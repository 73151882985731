import axios from "axios";

const api_url = process.env.REACT_APP_API_URL + "api/";

const api = axios.create({
  withCredentials: true,
  baseURL: api_url,
  headers: {
    "Content-Type": "application/json",
  },
});

export default class Api {
  //Test connections
  static testConnection = () => {
    return api.get("testConnection");
  };

  static getDetalhesCliente = (params) => {
    return api.post("getDetalhesCliente", params);
  };

  static adesaoCliente = (params) => {
    return api.post("adesaoCliente", params);
  };

  static adesaoNaoCliente = (params) => {
    return api.post("adesaoNaoCliente", params);
  };

  static getDataForAdesao = (params) => {
    return api.post("getDataForAdesao", params);
  };
}
